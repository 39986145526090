.page-form {
	&__title {
		font-size: 36px;
		font-weight: 400;
		margin-bottom: 30px;
	}

	&__input {
	}

	&__btn {
	}

	&__mobile-btn {
		position: fixed;
		z-index: 20;
		bottom: 20px;
		right: 20px;
		width: 52px;
		height: 52px;
		border-radius: 52px;
		padding: 0;

		i {
			font-size: 23px;

			&.fi-close {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(md) {
		&__mobile-btn {
			&.is-active {
				i {
					&.fi-offer {
						display: none;
					}
					&.fi-close {
						display: block;
					}
				}
			}
		}
	}
}
