.page-head {
	position: relative;
	padding: 30px 0 60px 0;

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		opacity: 0.5;
	}

	&__title {
		font-size: 30px;
		line-height: 1;
		font-weight: 400;
		margin-bottom: 0;
	}

	&__sub-title {
		font-size: 20px;
		font-weight: 500;
		margin-top: 20px;
	}

	&__description {
		font-size: 14px;
		font-weight: 500;
		margin-top: 10px;
	}

	&__image {
		display: block;
		margin: 30px auto 0;
	}

	&__btn {
		min-width: 180px;
	}

	&--dark {
		color: #fff;
	}

	@include media-breakpoint-down(sm) {
		padding: 30px 0;

		&__title {
			font-size: 26px;
			text-align: center;
		}

		&__description {
			text-align: center;
		}

		&--dark {
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	@include media-breakpoint-down(md) {
		margin: 0;

		&__image {
			display: none;
		}
	}
}
