/* ===== Helper */
$img-path: '../images/';
$font-path: '../fonts/';
$i: !important;
$font-name: 'gilroy';
$font-sizes: xlight, light, regular, medium, bold, xbold;

/* ===== Fonts */
$ff: 'Poppins';

/* ===== Colors */
$dark-slate-blue-two: #183d65 !default;
$azul: #1654d7 !default;
$twilight-blue: #0b3691 !default;
$pale-grey-four: #dde5e9 !default;
$pale-grey-two: #eff6fa !default;
$dark-slate-blue: #162a4c !default;
$black-two: #252525 !default;
$tiffany-blue-two: #7bf1f2 !default;
