.product-promo {
	min-height: calc(100vh - (167px + 131px));
	position: relative;

	@include media-breakpoint-down(md) {

		&__btn {
			position: relative;
			left: 0;
			transform: none;
			width: 100%;
		}

		&:before {
			display: none;
		}
	}

	@include media-breakpoint-down(sm) {
		&__btn {
			margin-top: 30px;

		}
	}
}
