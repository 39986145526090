.home-news {

	&__item {
		width: (100% / 3);
		padding-right: 30px;
		margin-right: 30px;
		border-right: 1px solid $pale-grey-four;
	}

	&__title {
		font-size: 19px;
		font-weight: 500;
		@include shortText(19px, 1.3, 3);
	}

	&__description {
		font-size: 14px;
		font-weight: 400;
		line-height: 1.6;
		margin-top: 30px;
		@include shortText(14px, 1.6, 4);
	}

	&__date {
		font-size: 14px;
		font-weight: 400;
		color: $azul;
		margin-top: 20px;
	}

	&__btn {
		margin-top: 25px;

	}

	@include media-breakpoint-down(sm) {
		&__item {
			width: 100%;
			padding: 0;
			margin: 0;
			border: none;

			&:after {
				display: none;
			}
		}
	}

}
