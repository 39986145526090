.page-sidebar {

	&--category {
		margin-top: (55px + (30 * 2) + (30 * 1)) * -1;
	}

	@include media-breakpoint-down(md) {
		&--category {
			margin-top: 0;
		}
	}

	@include media-breakpoint-down(sm) {
		&--category {
			margin-top: 30px;
		}
	}

}

.sidebar-widget {
	&__head {
	}

	&__body {
	}

	&__title {
	}

	&__description {
	}
}
