.product-list {
	&__item {
		position: relative;
		background: $pale-grey-two;
		margin-bottom: 30px;
		padding: 40px;
		color: $black-two;
		overflow: hidden;
		width: 100%;

		&:hover {
			.product-list__image {
				transform: translate(-50%, 0) scale(1.1);
			}
		}
	}

	&__head {
	}

	&__body {
		height: 380px;
	}

	&__foot {
		background: #fff;
		margin: 0 -20px;
		margin-bottom: -20px;
		padding: 0 20px;
		height: 55px;
	}

    &__link {
        margin: -40px;
    }


	&__title {
		font-size: 18px;
		font-weight: 500;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__sub-title {
		font-size: 14px;
		font-weight: 600;
		opacity: 0.3;
		margin-bottom: 5px;
	}

	&__image {
		transition: all ease 0.3s;
		transform: translate(-50%, 0);
		left: 50%;
		position: relative;
		max-width: 360px;
	}

	&__more-btn {
		font-size: 14px;
		font-weight: 400;
	}

	&__size {
		font-size: 17px;
		font-weight: 400;
	}

	@include media-breakpoint-down(md) {
		&__image {

		}
	}
}
