.product-catalog {

	height: 110px;
	background: $pale-grey-two;
	padding: 30px;

	&__icon {
		font-size: 30px;
		margin-right: 20px;
	}

	&__title {
		font-size: 18px;
		font-weight: 500;
	}

	&__btn {
		margin-left: auto;
	}
}
