.modal-header {
	padding: 25px 0;
	margin: 0 30px;
}

.modal-title {
	font-size: 22px;
	font-weight: 400;
}

.close {
	outline: none $i;
}
