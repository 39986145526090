.page-share {
	&__label {
		font-size: 13px;
		font-weight: 400;
		margin-right: 10px;
	}

	&__list {
	}

	&__link {
		color: #fff;
		width: 28px;
		height: 28px;
		border-radius: 3px;
		transition: all ease 0.3s;
		margin: 0 2px;

		&:hover {
			background: #fff;
			color: $azul;
		}
	}

	&__icon {
		font-size: 16px;
		line-height: 16px;
	}
}
