.home-promo-form {

	position: relative;

	&__input {
		height: 140px;
		background: $pale-grey-two;
		font-size: 16px;
		font-weight: 400;
		box-shadow: none;
		border-color: $pale-grey-two;
		padding: 0 40px;
		outline: none;
		border-radius: 0;

		&:focus {
			background: $pale-grey-two;
			box-shadow: none;
			border-color: $twilight-blue;
		}
	}

	&__btn {
		position: absolute;
		top: 50%;
		right: 40px;
		transform: translate(0, -50%);
	}

	@include media-breakpoint-down(sm) {

		margin-bottom: 80px;

		&__input {
			height: 80px;

		}

		&__btn {
			position: relative;
			top: 0;
			right: 0;
			transform: none;
			width: 50%;
			margin-top: 20px;
		}

	}

}
