.page-search {
	position: relative;

	&__input {
		height: 60px;
		font-size: 13px;
	}

	&__btn {
		position: absolute;
		top: 0;
		right: 0;
		height: 60px;
	}

	&__btn-icon {
		font-size: 14px;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 30px;
	}
}
