.home-blog {
	position: relative;
	background: #fff;
	box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.05);
	min-height: 530px;
	padding: 0 90px;
	padding-top: 90px + 45;
	padding-bottom: 90px - 30;

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include centerImage(100%, 100%);
		opacity: 0.8;
	}

	&__item {
		width: 100%;
	}

	&__title {
		font-size: 20px;
		font-weight: 500;
	}

	&__description {
		margin-top: 25px;

		p {
			font-size: 14px;
			font-weight: 400;
		}
	}

	&__flickity-list {
	}

	&__flickity-btn {
		position: absolute;
		top: 45px;
		left: 45px;
	}

	@include media-breakpoint-down(sm) {
		padding: 30px;
		min-height: inherit;

		&__flickity-btn {
			position: relative;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			margin-bottom: 30px;
		}
	}


}
