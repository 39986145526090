.dropdown {

	@include media-breakpoint-down(sm) {
		&:before {
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $modal-backdrop-bg;
			opacity: 0;
			z-index: -1;
		}
	}

	&.show {
		@include media-breakpoint-down(sm) {
			&:before {
				opacity: $modal-backdrop-opacity;
				z-index: 1;
			}
		}
	}
}

.dropdown-menu {
	box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.16);
	padding: 20px;
	min-width: 240px;
	margin-top: 13px;

	@include media-breakpoint-down(md) {
		position: fixed;
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		border-radius: 0;
		border-left: 0;
		border-right: 0;
	}
}

.dropdown-scroll {
	max-height: 240px;
	overflow-y: scroll;

	margin-top: -5px;
	padding-top: 5px;

	margin-left: -5px;
	padding-left: 5px;

	/* width */
	&::-webkit-scrollbar {
		width: 3px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #fff;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $pale-grey-four;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: $pale-grey-four;
	}
}

.dropdown-header {
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	color: $dark-slate-blue-two;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	i {
		font-size: 14px;
		line-height: 14px;
		color: $dark-slate-blue-two;
		cursor: pointer;
	}
}

.dropdown-item {
	font-size: 14px;
	font-weight: 500;
	outline: none $i;
}