.guide-list {
	&__item {
		background: $pale-grey-two;
		padding: 55px;
		margin-bottom: 30px;
		position: relative;
	}

	&__image-box {
		margin: -55px;
		margin-bottom: 30px;
	}

	&__image {
	}

	&__title {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 30px;
		@include shortText(20px, 1.3, 3);
	}

	&__description {
		font-size: 14px;
		line-height: 1.8;
		font-weight: 400;
		@include shortText(14px, 1.8, 3);

		p {
			line-height: 1.8;
			margin-bottom: 20px;
		}

		&--short {
			@include shortText(14px, 1.6, 3);
		}
	}

	&__btn {
		font-size: 16px;
		font-weight: 500;
		text-decoration: underline;
	}

	@include media-breakpoint-down(md) {
		&__item {
			margin-bottom: 15px;
			padding: 30px;
		}

		&__image-box {
			margin: -30px;
			margin-bottom: 30px;
		}

		&__description {
			margin-top: 30px;
		}
	}

	@include media-breakpoint-only(lg) {
		&__item {
			padding: 30px;
		}

		&__image-box {
			margin: -30px;
			margin-bottom: 30px;
		}
	}
}
