footer.site-foot {
	background: #fff;
}

.foot-customer-services {
	&__title {
		font-size: 32px;
		font-weight: 500;
	}

	&__sub-title {
		font-size: 20px;
		font-weight: 500;
	}

	&__btn {
		min-width: 180px;
	}

	@include media-breakpoint-down(md) {
		&__title {
			font-size: 24px;
			margin-top: 5px;
		}

		&__sub-title {
			font-size: 14px;
		}

		&__btn {
			margin-top: 30px;
		}
	}

	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

.foot-logo {
	text-align: center;

	&__link {
	}

	&__image {
	}
}

.foot-address {
	&__title {
		font-size: 28px;
		font-weight: 500;
		margin-bottom: 30px;
	}

	&__text {
		font-size: 14px;
		font-weight: 500;
		line-height: 1.6;
		color: $dark-slate-blue-two;
	}

	&__link {
		display: inline-block;
		color: $dark-slate-blue-two;
		font-size: 16px;
		font-weight: 600;

		&:hover {
			color: $dark-slate-blue-two;
			text-decoration: underline;
		}
	}
}

.foot-contact {
	color: $dark-slate-blue-two;

	&__icon {
		font-size: 36px;
	}

	&__label {
		font-size: 16px;
		font-weight: 600;
	}

	&__value {
		font-size: 24px;
		font-weight: 600;
		color: $dark-slate-blue-two;
		letter-spacing: -0.5px;

		&:hover {
			color: $dark-slate-blue-two;
			text-decoration: underline;
		}
	}
}

.foot-social {
	margin: 0 -5px;

	&__link {
		display: block;
		color: $dark-slate-blue-two;
		margin: 0 5px;
		padding: 5px;
		transition: all ease 0.3s;

		&:hover {
			color: $azul;
		}
	}

	&__icon {
		font-size: 20px;
	}
}

.foot-copyright {
	font-size: 13px;
	color: $dark-slate-blue;
	text-align: center;
}

.foot-links {
	&__title {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 30px;
	}

	&__menu {
		display: flex;
		flex-direction: column;
	}

	&__link {
		font-size: 14px;
		font-weight: 400;
		margin: 10px 0;
		color: $dark-slate-blue-two;

		&:hover {
			text-decoration: underline;
		}
	}
}
