$className: gallery;

.#{$className} {

	&__item {
		margin-bottom: 30px;

		&:hover {
			.#{$className}__image {
				transform: scale(1.05);
			}
		}
	}

	&__image {
		transition: all ease 0.3s;
	}

}
