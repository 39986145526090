.link-box {
	position: relative;
	padding: 30px;
	border: 1px solid;
	color: #fff;
	transition: all ease 0.3s;

	&:hover {
		box-shadow: $box-shadow;
		transform: scale(1.1);
		z-index: 1;
	}

	&__title {
		font-size: 16px;
		font-weight: 400;
	}

	&__btn {
		font-size: 14px;
		font-weight: 400;
		color: $tiffany-blue-two;
		margin-top: 20px;
	}
}
