$className: box-group;

.box-group {
	$spacer: 120px;

	margin: $spacer 0;
	position: relative;

	&__head {
		margin-bottom: $spacer / 2;

		&--border {
			&:after {
				content: '';
				display: block;
				width: 50px;
				height: 2px;
				background: $azul;
				margin: 20px 0 0;
			}
		}

		&--center {
			text-align: center;

			&:after {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&__body {
	}


	&__title {
		font-size: 36px;
		line-height: 1.2;
		font-weight: 400;
	}

	&__description {
		margin-top: 30px;

		p {
			font-size: 14px;
			font-weight: 400;
		}
	}

	&__btn {
		margin-top: 30px;
	}

	&__image {
	}


	&--promo {
		.#{$className}__title {
			font-size: 48px;
		}

		.#{$className}__description {
			font-size: 16px;
			font-weight: 400;
			line-height: 1.8;
		}

		@include media-breakpoint-down(sm) {
			text-align: center;
			margin: 70px 0;
			height: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.#{$className}__title {
				font-size: 32px;
			}
		}
	}

	&--category {
		position: relative;
		padding-top: 60px;
		margin-top: 0;

		.lines {
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			span {
				position: absolute;
				top: 0;
				width: 1px;
				height: 100%;
				background: $pale-grey-four;

				&:nth-child(1) {
					left: 25%;
					margin-left: (($grid-gutter-width / 2) / 2) * -1;
				}

				&:nth-child(2) {
					left: 50%;
				}

				&:nth-child(3) {
					left: 75%;
					margin-left: ($grid-gutter-width / 2) / 2;
				}
			}

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 150px;
				@include gradient-y(transparent, #fff, 0, 70%);
			}
		}
	}


	@include media-breakpoint-down(md) {
		&--category {
			.lines {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		$spacer: 80px;
		margin: $spacer 0;

		&__head {
			margin-bottom: $spacer / 2;
		}

		&__title {
			font-size: 28px;
		}
	}

}
