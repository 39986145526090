.product-gallery-box {
	position: sticky;
	top: 60px;

	&__head {
		margin-top: -50px;
		margin-bottom: 60px;
	}

	@include media-breakpoint-down(md) {
		&__head {
			margin-top: 0;
		}
	}
}

.product-main-gallery {
	&__item {
		width: 100%;

		&.is-selected {
			z-index: 1;
		}
	}
}

.product-thumb-gallery {
	margin: 0 -10px;

	&__item {
		width: calc((100% / 3) - 10px);
		margin-right: 10px;
		padding: 10px;
		opacity: 0.5;
		cursor: pointer;
		background: $pale-grey-four;
		border-radius: 5px;

		&.is-selected {
			opacity: 1;
		}
	}
}

.product-gallery {
	margin: 0 -10px;
}
