

.product-information {
	&__item {
		font-size: 13px;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $pale-grey-two;
		min-height: 60px;
	}

	&__label {
		color: fade_out($dark-slate-blue-two, 0.5);
	}

	&__value {
		color: $dark-slate-blue-two;
	}
}

.product-file-list {
	&__item {
		padding: 30px;
		background: $pale-grey-two;
		margin-bottom: 5px;
	}

	&__icon {
		color: $pale-grey-four;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		border: 1px solid $pale-grey-four;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__title {
		font-size: 18px;
		font-weight: 500;
	}

	&__btn {
	}

	@include media-breakpoint-down(sm) {
		&__item {
			padding: 20px;
		}
	}
}

.product-form {
	position: sticky;
	top: 30px;

	@include media-breakpoint-down(md) {
		position: fixed;
		z-index: 5;
		top: auto;
		bottom: -100%;
		left: 0;
		right: 0;
		width: 100%;
		transition: all ease 0.3s;

		&.is-active {
			bottom: 0;
		}
	}

	@include media-breakpoint-only(md) {
		width: 50%;
		margin: 30px;
	}
}
