/* Checkbox & Radio */
.custom-control {
	min-height: auto;
	cursor: pointer;

	& + .custom-control {
		margin-top: 10px;
	}
}

.custom-control-label {
	font-size: 13px;
	font-weight: 500;
	color: $dark-slate-blue-two;
	line-height: $custom-control-indicator-size;
	display: block;
	cursor: pointer;

	&:before, &:after {
		top: 0;
	}
}


/* Select */
.custom-select {
	cursor: pointer;
}


/* Custom */
.form-group-color-select {
	position: relative;

	[data-color-area] {
		width: 25px;
		height: 25px;
		position: absolute;
		top: 50%;
		left: 20px;
		transform: translate(0, -50%);
		background: $pale-grey-two;
		border: 1px solid $pale-grey-four;

		& ~ select {
			padding-left: 65px;
		}
	}

}