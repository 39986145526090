.home-product {

	&__item {
		position: relative;

		&--top {
			margin-top: -285px;
		}

		&:hover {
			.home-product__image-box {
				background: $azul;
			}

			.home-product__btn {
				color: #fff;
				border-color: #fff;
			}
		}
	}

	&__image-box {
		position: relative;
		min-height: 285px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $pale-grey-two;
		transition: all ease 0.3s;
	}

	&__image {
	}

	&__title {
		font-size: 18px;
		font-weight: 400;
		margin-top: 25px;
		padding: 0 20px;
	}

	&__btn {
		position: absolute;
		bottom: 20px;
		left: 20px;
		padding: 14px;
		transition: all ease 0.3s;

		i {
			font-size: 14px;
			padding-left: 1px;

		}
	}

	@include media-breakpoint-down(md) {
		&__item {
			margin-bottom: 30px;

			&--top {
				margin-top: 0;
			}
		}
	}

}
