.page-breadcrumb {
	position: relative;
	z-index: 5;
	display: flex;
    margin-top: -20px;
    margin-bottom: 20px;

	&__link {
		font-size: 14px;
		font-weight: 400;
		color: $dark-slate-blue-two;

		&:after {
			content: '/';
			display: inline-block;
			margin: 0 5px;
		}

		&:last-child {
			font-weight: 700;

			&:after {
				display: none;
			}
		}

		&:hover {
			color: $dark-slate-blue-two;
		}
	}

	@include media-breakpoint-down(md) {
		margin: 0;
		padding: 0;
		margin-top: 10px;
		justify-content: center;
		flex-flow: wrap;
	}

	@include media-breakpoint-only(md) {
		justify-content: flex-start;
	}
}
