@function strip-units($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

@function rem($pxval) {
	@if not unitless($pxval) {
		$pxval: strip-units($pxval);
	}

	$base: 16;
	@if not unitless($base) {
		$base: strip-units($base);
	}
	@return ($pxval / $base) * 1rem;
}


@mixin getFonts($fontname) {
	$fontpath: "../fonts/#{$fontname}/";
	@font-face {
		font-family: '#{$fontname}-xlight';
		src: url('#{$fontpath}xlight.eot');
		src: url('#{$fontpath}xlight.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}xlight.woff') format('woff'),
		url('#{$fontpath}xlight.ttf') format('truetype'),
		url('#{$fontpath}xlight.otf') format('opentype'),
		url('#{$fontpath}xlight.svg##{$fontname}-light') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
	@font-face {
		font-family: '#{$fontname}-light';
		src: url('#{$fontpath}light.eot');
		src: url('#{$fontpath}light.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}light.woff') format('woff'),
		url('#{$fontpath}light.ttf') format('truetype'),
		url('#{$fontpath}light.otf') format('opentype'),
		url('#{$fontpath}light.svg##{$fontname}-light') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
	@font-face {
		font-family: '#{$fontname}-regular';
		src: url('#{$fontpath}regular.eot');
		src: url('#{$fontpath}regular.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}regular.woff') format('woff'),
		url('#{$fontpath}regular.ttf') format('truetype'),
		url('#{$fontpath}regular.otf') format('opentype'),
		url('#{$fontpath}regular.svg##{$fontname}-regular') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
	@font-face {
		font-family: '#{$fontname}-medium';
		src: url('#{$fontpath}medium.eot');
		src: url('#{$fontpath}medium.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}medium.woff') format('woff'),
		url('#{$fontpath}medium.ttf') format('truetype'),
		url('#{$fontpath}medium.otf') format('opentype'),
		url('#{$fontpath}medium.svg##{$fontname}-medium') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
	@font-face {
		font-family: '#{$fontname}-bold';
		src: url('#{$fontpath}bold.eot');
		src: url('#{$fontpath}bold.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}bold.woff') format('woff'),
		url('#{$fontpath}bold.ttf') format('truetype'),
		url('#{$fontpath}bold.otf') format('opentype'),
		url('#{$fontpath}bold.svg##{$fontname}-bold') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
	@font-face {
		font-family: '#{$fontname}-xbold';
		src: url('#{$fontpath}xbold.eot');
		src: url('#{$fontpath}xbold.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}xbold.woff') format('woff'),
		url('#{$fontpath}xbold.ttf') format('truetype'),
		url('#{$fontpath}xbold.otf') format('opentype'),
		url('#{$fontpath}xbold.svg##{$fontname}-bold') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
}

@mixin article() {
	@extend .clearfix;
	.paragraph {
		font-size: 14px;
		font-weight: 400;
		line-height: 1.8;
	}

	h1 {
		font-size: 42px;
		font-weight: 400;
	}
	h2 {
		font-size: 36px;
		font-weight: 400;

		small {
			font-size: 65%;
		}
	}
	h3 {
		font-size: 20px;
		font-weight: 500;
	}
	h4 {
		font-size: 36px * 0.65;
		font-weight: 500;
	}
	h5 {
		font-size: 36px * 0.50;
		font-weight: 500;
	}
	h6 {
	}

	p {
		margin: 0 0 $paragraph-margin-bottom 0;
		@extend .paragraph;

		a {
			@extend .paragraph;
			opacity: 0.4;
			transition: all ease 0.3s;

			&:hover {
				color: $azul;
				opacity: 1;
				text-decoration: underline;
			}
		}
	}


	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			@extend .paragraph;
			line-height: 1.6;
			margin-bottom: 20px;
			position: relative;
			padding-left: 30px;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 10px;
				height: 10px;
				background: #fff;
				margin: 7px 15px 0 0;
				border-radius: 10px;
				border: 2px solid $twilight-blue;
			}
		}
	}

	img {
		margin-bottom: 50px;
		max-width: 100%;

		&[style*=left] {
			margin-right: 60px;
		}

		&[style*=right] {
			margin-left: 60px;
		}
	}

	@include media-breakpoint-down(md) {
		img {
			width: 100%;
			margin-right: 0 $i;
			margin-left: 0 $i;
		}
	}
}

@mixin shortText($font-size, $line-height, $rows) {
	height: $font-size * $line-height * $rows;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: $rows;
	-webkit-box-orient: vertical;
}

@mixin centerImage($width: '', $height: '') {
	@if $width {
		width: $width;
	}
	@if $height {
		height: $height;
	}
	object-fit: cover;
	object-position: center;
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}
