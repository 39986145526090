/* ===== Theme Variable */
@import "theme/variable";
@import "theme/mixin";
@import "bootstrap-custom/bootstrap-func";
@import "bootstrap-custom/bootstrap-var";
@import "theme/plugin-variable";

/* ===== Node Modules */
@import "vendor";

/* ===== Bootstrap */
@import "bootstrap-custom/bootstrap";

/* ===== Other Plugin */
@import "plugins/fancybox";
@import "plugins/flickity";

/* ===== Partials */
@import "partials/header";
@import "partials/footer";

/* ===== Global */
@import "theme/helper";
@import "theme/font-face";
@import "theme/icon-font";
@import "theme/theme";

/* ===== Global */
@import "../components/page-article/page-article";
@import "../components/page-author/page-author";
@import "../components/page-breadcrumb/page-breadcrumb";
@import "../components/page-btn-group/page-btn-group";
@import "../components/page-content/page-content";
@import "../components/page-form/page-form";
@import "../components/page-head/page-head";
@import "../components/page-search/page-search";
@import "../components/page-share/page-share";
@import "../components/page-sidebar/page-sidebar";
@import "../components/page-tab/page-tab";
@import "../components/box-group/box-group";

/* Theme */
@import "../components/guide-list/guide-list";
@import "../components/contact-info/contact-info";
@import "../components/link-box/link-box";
@import "../components/category-list/category-list";
@import "../components/product-list/product-list";
@import "../components/product-detail/product-detail";
@import "../components/product-gallery/product-gallery";
@import "../components/product-promo/product-promo";
@import "../components/product-catalog/product-catalog";
@import "../components/home-news/home-news";
@import "../components/home-blog/home-blog";
@import "../components/home-product/home-product";
@import "../components/home-promo/home-promo";
@import "../components/home-promo-form/home-promo-form";
@import "../components/gallery/gallery";
@import "../components/cart/cart";
