.home-promo {

	&__item {
		position: relative;
	}

	&__text-box {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 30px;
		padding-top: 60px;
		@include gradient-y(transparent, rgba(#000, 0.5));
	}

	&__title {
		font-size: 16px;
		font-weight: 400;
		color: #fff;
	}

	&__btn {
		font-size: 14px;
		font-weight: 400;
		color: $tiffany-blue-two;
		margin-top: 20px;
	}

}
