.category-list {
	&__item {
		position: relative;
		background-color: $pale-grey-two;
		border: 1px solid $pale-grey-two;
		min-height: 260px;
		margin-bottom: 30px;
		transition: all ease 0.3s;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom right;

		&--big {
			min-height: 550px;
		}

		&:hover {
			background-color: #fff;
			box-shadow: $box-shadow;
		}
	}

	&__text-box {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 30px;
	}

	&__image {

	}

	&__title {
		font-size: 18px;
		font-weight: 400;
	}

	@include media-breakpoint-down(md) {
		&__item {
			&--big {
				min-height: 260px;
			}
		}
	}
}
