.page-btn-group {

	background: $pale-grey-two;
	border: 1px solid $pale-grey-four;
	border-radius: 5px;
	overflow: hidden;

	&__btn {
		font-size: 14px;
		font-weight: 500;
		color: fade_out($black-two, 0.5);

		&:hover {
			color: $black-two;
		}

		&--active {
			background: #fff;
			color: $black-two;
			border: 1px solid $pale-grey-four;
			margin: -1px;
		}
	}

	@include media-breakpoint-down(md) {
		&__btn {
			font-size: 12px;
			line-height: 1.2;
		}
	}
}
