body {

    &.site-head-light {
        .site-head {
            box-shadow: none;
            background: $azul;
        }

        .head-logo {
            &__image {
                &--light {
                    display: block;
                }

                &--dark {
                    display: none;
                }
            }
        }

        .head-menu {
            &__link {
                color: #fff;

                &--active {
                    border-color: #fff;
                }
            }
        }

        .head-contact {
            &__label, &__value {
                color: #fff;
            }
        }

        .head-cart {
            &__btn {
                color: #fff;
            }
        }

        .hamburger {
            &.is-active {
                .hamburger-inner {
                    background: #fff;

                    &:before, &:after {
                        background: #fff;
                    }
                }
            }

            .hamburger-inner {
                background: #fff;

                &:before, &:after {
                    background: #fff;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding-top: $header-height;
    }

    img {
        max-width: 100%;
    }
}

.product-detail-content {
}

.faq-list {
    .item {
        border: 1px solid $pale-grey-two;
        margin-bottom: $grid-gutter-width - 15;

        .item-head {
            .btn {
                font-size: 20px;
                font-weight: 500;
                color: $dark-slate-blue-two;
                background: $pale-grey-two;
                padding: $grid-gutter-width ($grid-gutter-width * 2);
                text-align: left;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row-reverse;
                outline: none $i;
                box-shadow: none $i;


                @extend .fi;
                @extend .fi-angle-down;

                &:before {
                    font-size: 14px;
                    width: 43px;
                    height: 43px;
                    border-radius: 43px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &[aria-expanded="true"] {
                    background: #fff;

                    &:before {
                        transform: rotate(180deg);
                        background: $pale-grey-two;
                    }
                }
            }
        }

        .item-body {
            @include article();

            div {
                padding: $grid-gutter-width ($grid-gutter-width * 2);
                padding-top: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .item {
            .item-head {
                .btn {
                    padding: $grid-gutter-width - 10;
                    font-size: 16px;
                }
            }

            .item-body {
                div {
                    padding: $grid-gutter-width - 10;
                }
            }
        }
    }
}
