
/* ===== Font Awesome */
$fa-font-path:         "../fonts/font-awesome" !default;
$fa-font-size-base:    16px !default;
$fa-font-display:      auto !default;
$fa-css-prefix:        fa !default;
$fa-version:           "5.11.2" !default;
$fa-border-color:      #eee !default;
$fa-inverse:           #fff !default;
$fa-li-width:          2em !default;
$fa-fw-width:          (20em / 16);
$fa-primary-opacity:   1 !default;
$fa-secondary-opacity: .4 !default;

/* ===== Hamburger Menu */
$hamburger-padding-x           : 0 !default;
$hamburger-padding-y           : 0 !default;
$hamburger-layer-width         : 28px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-spacing       : 5px !default;
$hamburger-layer-color         : $azul !default;
$hamburger-layer-border-radius : 10px !default;
$hamburger-hover-opacity       : 1 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

/* ===== Plyr JS */
$plyr-color-main: rgba($azul, 0.8);
$plyr-control-icon-size: 24px !default;
$plyr-control-spacing: 10px !default;
$plyr-control-padding: ($plyr-control-spacing * 0.4) !default;
$plyr-control-radius: 3px !default;
