.contact-info {

	padding: 40px;
	text-align: center;
	background: $azul;
	color: #fff;
	min-height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&__label {
		font-size: 11px;
		font-weight: 400;
		color: rgba(#fff, 0.5);
		margin-bottom: 15px;
	}

	&__title {
		font-size: 36px;
		font-weight: 400;
	}

	&__sub-title {
		font-size: 18px;
		font-weight: 400;
		color: rgba(#fff, 0.5);
		margin-top: 10px;
	}

	&__description {
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5;
	}

	&__btn {
		margin-top: 20px;
	}

	hr {
		border-color: rgba(#fff, 0.3);
		margin: 30px 0;
	}

	a {
		color: #fff;

		&:hover {
			text-decoration: underline;
		}
	}

	@include media-breakpoint-down(sm) {
		padding: 20px;
	}

	@include media-breakpoint-down(md) {
		&__title {
			font-size: 32px;
		}
	}

}
